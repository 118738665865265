export const getEnvironment = () => {
    const hostname = window.location.hostname;
    const isLocalhost = hostname === 'localhost' || hostname === '127.0.0.1' || hostname === '::1';
    const isDevelopment = (process.env.REACT_APP_ENV || process.env.NODE_ENV) === 'development';

    if (isLocalhost) {
        return 'localhost';
    } else if (isDevelopment) {
        return 'development';
    } else {
        return 'production';
    }
}

const environment = getEnvironment()
const BASE_URL = environment === 'localhost' ? "http://localhost:8082" : (environment === 'development' ? "https://test.sysbrew.in" : "https://sarathi.sunviksteels.com/api")
// const BASE_URL = () => {
//     if (environment === 'localhost') {
//         return "http://localhost:8082"
//     } else if (environment === 'development') {
//         return "https://test.sysbrew.in"
//     }
//     return "https://sarathi.sunviksteels.com/api"
// }

export const URLS = {
    LOGIN: `${BASE_URL}/admin/login`,
    AUTHENTICATE_TOKEN: `${BASE_URL}/admin/token`,
    FILE_URL: `${BASE_URL}/v1/file`,
    CATEGORIES: `${BASE_URL}/v1/categories`,
    SCHEME_MINIMAL: `${BASE_URL}/v1/schemes/minimal`,
    MINIMAL_SLABS: `${BASE_URL}/admin/minimal/slabs`,
    USERS_MINIMAL: `${BASE_URL}/v1/minimal/users`,
    TRANSACTIONS_REPORT: `${BASE_URL}/v1/transactions/report`,
    TRANSACTIONS_ACCOUNTING_REPORT: `${BASE_URL}/v1/transactions/accounting/report`,
    TRANSACTIONS_V2: `${BASE_URL}/v2/transactions`,
    CURRENT_RATE: `${BASE_URL}/v1/ticker/latest`,
    UPDATE_RATE: `${BASE_URL}/admin/ticker`,
    LIST_RATE: `${BASE_URL}/v1/ticker`,
    REWARDS: `${BASE_URL}/v1/rewards`,
    REWARD_DETAILS: `${BASE_URL}/v1/reward/{id}`,
    UPDATE_REWARD_STATUS: `${BASE_URL}/admin/reward/{id}/{status}`,
    MARK_REWARD_CLAIMED: `${BASE_URL}/v1/claim/{id}`,
    RUN_REWARDS_JOB: `${BASE_URL}/job/rewards`,
    TRANSACTION_DETAILS: `${BASE_URL}/v1/transaction/{id}`,
    APPROVE_TRANSACTION: `${BASE_URL}/admin/v2/approve/transaction/{id}`,
    APPROVE_TRANSACTION_2: `${BASE_URL}/admin/v2/add/transaction/{id}/others`,
    REJECT_TRANSACTION: `${BASE_URL}/admin/reject/transaction/{id}`,
    EDIT_TRANSACTION: `${BASE_URL}/admin/update/transaction/{id}`,
    ADD_TRANSACTION: `${BASE_URL}/v2/transaction`,
    SCHEMES: `${BASE_URL}/v1/schemes`,
    SCHEME_DETAILS: `${BASE_URL}/v1/scheme/{id}`,
    CURRENT_SCHEME: `${BASE_URL}/v1/current/scheme`,
    ADD_SCHEME_SLAB: `${BASE_URL}/admin/scheme/slab`,
    ACTIVATE_SCHEME: `${BASE_URL}/admin/scheme/status`,
    REMOVE_SCHEME_SLAB: `${BASE_URL}/admin/scheme/slab/{id}`,
    ADD_SCHEME: `${BASE_URL}/admin/scheme`,
    NOTIFICATIONS: `${BASE_URL}/admin/notifications`,
    ANNOUNCEMENTS: `${BASE_URL}/admin/announcements`,
    GENERAL_ANNOUNCEMENT: `${BASE_URL}/v1/announcement/general`,
    ANNOUNCEMENT: `${BASE_URL}/admin/announcement`,
    ANNOUNCEMENT_ID: `${BASE_URL}/admin/announcement/{id}`,
    USERS: `${BASE_URL}/admin/users`,
    REFERENCES: `${BASE_URL}/admin/references`,
    MEMBERS_REPORT: `${BASE_URL}/admin/users/report`,
    USER_DETAIL: `${BASE_URL}/admin/user/{id}/details`,
    UPDATE_STATUS: `${BASE_URL}/admin/user/status`,
    UPDATE_REFERENCE_STATUS: `${BASE_URL}/admin/reference/status`,
    PENDING_CHANGES: `${BASE_URL}/admin/user/changes`,
    MEMBER_PENDING_CHANGES: `${BASE_URL}/admin/user/{id}/pending/changes`,
    UPDATE_CHANGES_STATUS: `${BASE_URL}/admin/user/changes/status`,
    FETCH_MEMBERS: `${BASE_URL}/members/list`,
    LIST_ADMINS: `${BASE_URL}/admin/list`,
    ADD_ADMIN_URL: `${BASE_URL}/admin/add`,
    ADMIN_SEND_NOTIFICATION: `${BASE_URL}/admin/notification`,
    ADMIN_URL: `${BASE_URL}/admin/{id}`,
    ORGANISATION_URL: `${BASE_URL}/admin/organisation`,
    ADD_USER: `${BASE_URL}/admin/user/register`,
    UPDATE_USER: `${BASE_URL}/v1/user/{type}`,
    ADD_STAFF: `${BASE_URL}/admin/staff`,
    MASTER_REASONS: `${BASE_URL}/v1/reasons/{type}`,
    REASON: `${BASE_URL}/admin/reason`,
    REASON_TYPE_CODE: `${BASE_URL}/admin/reason/{type}/{code}`,
    SITE: `${BASE_URL}/v1/site`,
    SITES: `${BASE_URL}/admin/sites`,
    SITES_MINIMAL: `${BASE_URL}/v1/minimal/sites`,
    SITE_STATUSES: `${BASE_URL}/admin/site/{id}/statuses`,
    SITE_SUMMARY: `${BASE_URL}/admin/site/{id}/summary`,
    SITE_DETAILS: `${BASE_URL}/v2/site/{id}`,
    SITES_EXPORT: `${BASE_URL}/admin/sites/report`,
    UPDATE_SITE_STATUS: `${BASE_URL}/admin/site/status`,
    EMPLOYEES: `${BASE_URL}/admin/users?employee=true`,
    GET_EMPLOYEES: `${BASE_URL}/v1/minimal/users?employee=true`,
    REASSIGN_SITE: `${BASE_URL}/admin/site/reassign`,
    SET_SITE_INCHARGE: `${BASE_URL}/v2/site/{id}/incharge/{userId}`,
    LINK_USER_TO_SITE: `${BASE_URL}/v2/site/{id}/user/{userId}`,
    ADD_EMPLOYEE: `${BASE_URL}/admin/user`,
    EDIT_EMPLOYEE: `${BASE_URL}/admin/update/user`,
    ACTIVATE_EMPLOYEE: `${BASE_URL}/admin/employee/status`,
    CHANGE_PASSWORD: `${BASE_URL}/admin/employee/password`,
    TASK_CATEGORIES: `${BASE_URL}/v1/task/categories`,
    TASKS: `${BASE_URL}/admin/tasks`,
    UPDATE_TASK_STATUS: `${BASE_URL}/v1/task/status`,
    REASSIGN_TASK: `${BASE_URL}/admin/task/reassign`,
    ADD_TASK: `${BASE_URL}/admin/task`,
    SITE_TASK_DETAIL: `${BASE_URL}/admin/site/status/{taskId}`,
    FEEDS: `${BASE_URL}/v1/blogs`,
    FEED: `${BASE_URL}/v1/blog`,
    MEETINGS: `${BASE_URL}/admin/meetings`,
    MEETING_ATTENDEES: `${BASE_URL}/admin/meeting/{id}/attendees`,
    MEETING: `${BASE_URL}/v1/meeting`,
    MEETING_ID: `${BASE_URL}/v1/meeting/{id}`,
    MEETING_ATTENDEES_REPORT: `${BASE_URL}/admin/meeting/{id}/attendees/report`,
    MEETING_INVITEES_STATS: `${BASE_URL}/admin/meeting/{id}/invitees/stats`,
    TMT_BRANDS: `${BASE_URL}/v1/tmt/brands`,
    TMT_BRAND_BY_ID: `${BASE_URL}/v1/tmt/brand/{name}`,
    SITE_FOLLOW_UP: `${BASE_URL}/admin/site/followups/{days}`,
    UPDATE_TRANSACTION_STATUS: `${BASE_URL}/v2/update/transaction/status`,
    USER_RESEND_WELCOME_MESSAGE: `${BASE_URL}/admin/user/{id}/resend/welcome/message`,
    ADD_USER_GST: `${BASE_URL}/admin/user/gst`,
    REMOVE_USER_GST: `${BASE_URL}/admin/user/{id}/gst/{gst_no}`,

    // Inventory
    INVENTORY_SOURCES: `${BASE_URL}/admin/inventory/sources`,
    INVENTORY_SOURCE: `${BASE_URL}/admin/inventory/source`,
    INVENTORY_SUMMARY: `${BASE_URL}/admin/inventory/summary`,
    INVENTORY_SUMMARY_FOR_SLAB: `${BASE_URL}/admin/inventory/summary/{id}`,
    INVENTORY_MOVEMENTS: `${BASE_URL}/admin/inventory/movements`,
    INVENTORY: `${BASE_URL}/admin/inventory`,
    INVENTORY_ID: `${BASE_URL}/admin/inventory/{id}`,

    // Dealer Rewards
    SAMPLES: `${BASE_URL}/admin/samples/import/{type}`,
    DEALER_IMPORT: `${BASE_URL}/admin/dealer/rewards/upload`,
    DEALER_REWARDS: `${BASE_URL}/admin/dealer/rewards`,
    DEALERS_MINIMAL: `${BASE_URL}/admin/dealers`,
    
    // Dealer Purchases
    DEALER_PURCHASES_IMPORT: `${BASE_URL}/admin/dealer/purchases/upload`,
    DEALER_PURCHASE_RECONCILE: `${BASE_URL}/admin/dealer/purchases/reconcile`,
}